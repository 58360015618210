
import React from 'react';
import { FilledInput } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { useState } from 'react';

function Input(props) {

    const [question, setQuestion] = useState('');

    function handleChange(e) {
        setQuestion(e.target.value);
    }

    function handleSubmit() {
        if (question !== '') {
            props.handleSubmit(question);
            setQuestion('');
        }
    }

    return (

        <FilledInput sx={{ width: '100%', backgroundColor: '#ffffff', boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, .1)' }}
            onChange={handleChange} id="outlined-basic" variant="outlined" outlined="false"
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleSubmit();
                }
            }}
            placeholder='Ask a question'
            value={question}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="send"
                        onClick={handleSubmit}
                    >
                        <SendIcon />
                    </IconButton>
                </InputAdornment>}
        />

    );
}

export default Input;


