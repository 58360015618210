import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import AcronymReferences from './AcronymReferences';
import Button from '@mui/material/Button';
function AcronymFilter({ acronyms }) {

    const [currentLetter, setCurrentLetter] = useState('A');
    const [filterType, setFilterType] = useState(2);  // null for no filter, 1 for 'n' length of 1, 2 for 'n' length > 1
    const [filteredData, setFilteredData] = useState([]);
    const [selectedAcronym, setSelectedAcronym] = useState(''); // for references

    useEffect(() => {
        const result = acronyms.filter(item => {

            // Check for A-Z + (non A-Z) filter
            if (currentLetter === 'Non-A-Z') {
                if (item.a.match(/[A-Za-z]/)) {
                    return false;
                }
            } else if (item.a[0] !== currentLetter) {
                return false;
            }

            // Check for 'n' length filter
            if (filterType === 1 && item.n.length !== 1) {
                return false;
            } else if (filterType === 2 && item.n.length <= 1) {
                return false;
            } else if (filterType === 3 && countOccurrences(acronyms, item.a) <= 1) {
                return false;
            }

            return true;
        });

        setFilteredData(result);
    }, [acronyms, currentLetter, filterType]);

    function countOccurrences(arr, value) {
        return arr.reduce((acc, curr) => (curr.a === value ? acc + 1 : acc), 0);
    }

    const handleShowReferences = (acronym) => {
        setSelectedAcronym(acronym);
    }

    return (
        <Box sx={{ padding: '20px' }}>
 
            <AcronymReferences 
                acronym={selectedAcronym}
                handleClear={() => setSelectedAcronym('')} 
            /> 

            {/* Letter Filter */}
            <Box sx={{ marginBottom: '20px' }}>
                {[
                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
                    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
                    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
                    'Y', 'Z', 'Non-A-Z'
                ].map(letter => (
                    <Chip
                        key={letter}
                        sx={{ margin: '5px' }}
                        label={letter}
                        variant={letter === currentLetter ? "" : "outlined"}
                        onClick={() => setCurrentLetter(letter)
                        } />
                ))}
            </Box>

            {/* 'n' Length Filter */}
            <FormControl sx={{width: '100%', backgroundColor: '#EEEEEE', paddingLeft: '20px'}}>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filterType}
                    onChange={(event) => setFilterType(parseInt(event.target.value, 10))}
                >
                    <FormControlLabel value={0} control={<Radio />} label="All" />
                    <FormControlLabel value={1} control={<Radio />} label="One Doc Reference" />
                    <FormControlLabel value={2} control={<Radio />} label="Multiple Doc References" />
                    <FormControlLabel value={3} control={<Radio />} label="Duplicates" />
                </RadioGroup>
            </FormControl>


            {/* Display Data */}
            <Box sx={{marginTop: '30px', backgroundColor: '#EEEEEE'}}>
                {filteredData.map((item, index) => (
                    <Box key={item.a + String(index)} sx={{display: 'flex', marginBottom: '1px', padding: '5px', backgroundColor: 'white'}}>
                        <Box sx={{width: '100px', marginLeft: '20px'}}>
                            <Typography sx={{fontWeight: '600'}} variant="body">
                                {item.a}
                            </Typography>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <Typography variant="body"> 
                                {item.d} 
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant="outlined" size="small" onClick={()=>handleShowReferences(item)}>{item.n.length}</Button>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>


    );
}

export default AcronymFilter;


