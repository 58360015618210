import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { API_BASE_URL } from '../config';
import { Box } from '@mui/material';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const Document = () => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { docId } = useParams();
    const [docInfo, setDocInfo] = useState([]);
    const [docNum, setDocNum] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');   

    // const { error, execute, isLoading } = useFetchWithMsal({
    const {execute, isLoading } = useFetchWithMsal({
        //scopes: protectedResources.apiTodoList.scopes.write
    });

    

    // Fetch from URL
    useEffect(() => {
        if (docId) {

            setErrorMessage('');
            setDocInfo([]);

            execute("GET", `${API_BASE_URL}/document/${docId}`).then((response) => {
                
                if (response && response.error) {
                    setErrorMessage(response.error);
                    return;
                }

                console.log(response);
                setDocInfo(response);
            });
        }
    }, [docId, execute]);


    // Fetch from click
    const handleFetchDocument = useCallback((overideDocNum = null) => {

        const documentNumber = overideDocNum || docNum;

        if (!documentNumber) {
            return;
        }
        
        console.log('Doc to Fetch:',documentNumber);

        setErrorMessage('');
        setDocInfo([]);

        execute("GET", `${API_BASE_URL}/document/${documentNumber}`).then((response) => {

            if (response && response.error) {
                setErrorMessage(response.error);
                return;
            }
            
            console.log(response);
            setDocInfo(response);
        });

    }, [docNum, execute]);


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleFetchDocument(e.target.value);
          e.preventDefault();  // This will prevent the default behavior of 'Enter' key (i.e., form submission or click in some cases)
        }
      };




    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (<>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '50px' }}>
                        <Paper sx={{ width: '100%', maxWidth: '1000px' }}>
                            <Box sx={{ width: '100%', paddingLeft: '30px', paddingBottom: '20px'}}>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  paddingBottom: '15px' }}>
                                    <Typography variant="h6" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                        Document Info
                                    </Typography>
                                </Box>
                                <Typography variant="body1" sx={{ paddingBottom: '15px' }}>
                                    Enter a document number to view its information, e.g. MD-10-87
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center',  paddingBottom: '15px' }}>
                                    <Box sx={{width: '400px'}}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Document Number"
                                            variant="outlined"
                                            onChange={(e) => setDocNum(e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            fullWidth
                                            focused
                                        />
                                    </Box>

                                    {/* <Box >
                                        <Button
                                            onClick={()=>handleFetchDocument(docNum)}
                                            variant="contained"
                                            sx={{
                                                marginTop: '20px', marginBottom: '20px', marginLeft: '20px', backgroundColor: '#db1f26',
                                                '&:hover': {
                                                    backgroundColor: '#db1f26',
                                                }
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Box> */}
                                </Box>
                                
                            </Box>
                        </Paper>



                        {isLoading ? <Paper sx={{ width: '100%', maxWidth: '1000px', marginTop: '20px', paddingTop:'20px' }}>
                            <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}>Loading...</Typography>
                        </Paper> : null}

                        {errorMessage ? <Paper sx={{ width: '100%', maxWidth: '1000px', marginTop: '20px', paddingTop:'20px' }}>
                            <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}>{errorMessage}</Typography>
                        </Paper> : null}
                        
                        {docInfo && docInfo.meta ? (
                            <Paper sx={{ width: '100%', maxWidth: '1000px', marginTop: '20px' }}>
                                <Box sx={{padding: '30px'}}>
                                    <Typography variant="h6" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                        {docInfo.meta.title ? docInfo.meta.title : ''}
                                    </Typography>

                                    <Typography variant="body1">
                                        Document: {docInfo.docNumber ? docInfo.docNumber : 'n/a'}
                                    </Typography>
              
                                    <Typography variant="body1">
                                        Version: {docInfo.meta.version ? docInfo.meta.version : 'n/a'}
                                    </Typography>
                                    <Typography variant="body1">
                                        Type: {docInfo.meta.type ? docInfo.meta.type : 'n/a'}
                                    </Typography>
                                    <Typography variant="body1">
                                        File Type: {docInfo.meta.fileType ? docInfo.meta.fileType : 'n/a'}
                                    </Typography>
                                    <Typography variant="body1">
                                        Pages: {docInfo.meta.pageCount ? docInfo.meta.pageCount : 'n/a'}
                                    </Typography>

                                    <Typography variant="body1" sx={{marginTop: '20px'}}>
                                        <strong>Summary:</strong><br/>
                                        {docInfo.summary ? docInfo.summary.summary : 'n/a'}
                                    </Typography>

                                    {docInfo.meta.linkedDocs.linkedDocs.length > 0 ? (<>
                                        <Accordion sx={{marginTop: '30px'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{fontWeight: '600'}}>References to other Documents ({docInfo.meta.linkedDocs.linkedDocs.length})</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {docInfo.meta.linkedDocs.linkedDocs.map((doc) => (
                                                <Chip 
                                                    key={doc} 
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }} 
                                                    label={doc} variant="outlined"
                                                    onClick={() => {
                                                        //setDocNum(doc);
                                                        handleFetchDocument(doc);
                                                    }} 
                                                />
                                                ))}
                                            </AccordionDetails>
                                            </Accordion>
                                    </>) : null}

                                    {docInfo.meta.urls.length > 0 ? (<>
                                        <Accordion sx={{marginTop: '30px'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{fontWeight: '600'}}>URLs ({docInfo.meta.urls.length})</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {docInfo.meta.urls.map((url) => (
                                                    <a href={url.urls} target="_blank" rel="noreferrer" key={url}>
                                                        <Chip 
                                                            sx={{ marginRight: '10px', marginBottom: '10px' }} 
                                                            label={url.urls} variant="outlined"
                                                        />
                                                    </a>
                                                ))}
                                            </AccordionDetails>
                                            </Accordion>
                                    </>) : null}


                                    {docInfo.acronyms.length > 0 ? (<>
                                        <Accordion sx={{marginTop: '30px'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{fontWeight: '600'}}>Acronyms ({docInfo.acronyms.length})</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {docInfo.acronyms.map((acronym, i) => (
                                                    <Box key={acronym.acronym + String(i)} sx={{display: 'flex', marginBottom: '1px', padding: '5px', backgroundColor: 'white'}}>
                                                    <Box sx={{width: '100px', marginLeft: '20px'}}>
                                                        <Typography sx={{fontWeight: '600'}} variant="body">
                                                            {acronym.acronym}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{flex: 1}}>
                                                        <Typography variant="body"> 
                                                            {acronym.definition} 
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                ))}
                                            </AccordionDetails>
                                            </Accordion>
                                    </>) : null}

                                </Box>
                            </Paper>
                        ) : null}


                    </Box>
         
        </>) : null
}
            </AuthenticatedTemplate >

    <UnauthenticatedTemplate>
        Unauthorised
    </UnauthenticatedTemplate>

        </>
    );
};