import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import QuizIcon from '@mui/icons-material/Quiz';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

const questions = [
    'What procedure will tell me how to decommission externally owned utilities in our corridor?',
    'What does an asset manager need to do when he receives a wayleave application form?',
    'What qualifications are needed if I want to clip points in the corridor?',
    'Describe the steps I need to follow to use an angle grinder.',
    'Where can I find automatic gates?',
    'What is a derogation?',
    'What assurance is required by QR in relation to signalling design changes?',
    'Can an electrician reuse cable on a project with QR?',
    'Who needs to maintain as constructed drawings?',
    'If there was an emergency at Mayne Yard who is in charge and how do I know who it is?',
    'What criteria do I use to assess risk?',
    'If someone need to trim the trees at a rail yard with a pole pruner what should they do before they start work?',
    'Where does rail traffic crew find specific instructions that apply to rail traffic crew only?',
    'What might a small kick in the track become?',
    'Summarise MD-12-103',
    'How do you perform SID? (Safety in Design)',
    'What PPE does an electrician need to wear when undertaking work on the railway in the rail corridor?',
    'What is a SEMS document?',
    'As a discipline head what are my responsibilities in regard to managing and updating SEMS documents?',
    'What other documents are related to or support MD-18-393?',
    'What are QRL’s obligations in storing paper-based or electronic records relating to construction projects?',
    'What requirements must external contractors satisfy in providing or using structural steelwork in QRL construction works?',
    'What degree of angle is the maximum incline or decline tolerance on QRL railways?',
    'Where and to what extent is weed matting required in the rail corridor?',
    'Which QRL employees are authorised to sign documents on behalf of QRL?',
    'When does a person consider human factors?',
    'Which documents references Sunlander Road?',
    'Which documents note existence of signal WMC 212?',
    'If I needed to update WMC 212 to WMC 212 rev 1 which documents do I need?',
    'Where do I find the phone number for the freight facilitator for rail corridor entry?',
    'What is the phone number for freight facilitator for rail corridor entry?',
    'Who uses radio TCR channel CH23?',
    'If I needed to operate the cairns tilt train and had to carry additional equipment, what document do I use?',
    'Where is the shunter located when I am operating the cairns tilt train in Mayne Yard?',
    'What is a “SER” and where do I find it?',
    'How does QR manage surge?',
    'What do surge arrestors need?',
    'Which document mentions RMC the most?',
    'How many times does each document mention TCU?',
    'As a Rollingstock Presentation Staff member, how do I remove graffiti from rollingstock?',
    'Who performs the road lockout in the various areas of Mayne Yard?',
    'As a rollingstock presentation staff member, how do I turn off the electrical equipment for the graffiti shed in Mayne?',
    'What order do I need to isolate and lock out the rollingstock?',
    'I’m a track worker, which lockout procedures do I use?',
    'As a Rail Traffic Crew at Mayne, what do I do with passengers over carried passengers?',
    'How do I reset an axle counter at Mayne Yard?',
    'As a maintenance worker, what do I do if someone has left the site but forgotten to remove their personal padlock from the lockout box?',
    'As an NCO, what do I do if I need to quarantine rollingstock carrying items I think may be dangerous?',
    'Define Lockout',
    'Electric rail traffic balloon (also can be ETB and balloon)'
];

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Sample Questions</DialogTitle>
            <List sx={{ pt: 0, paddingRight: '35px' }}>
                {questions.map((q, i) => (
                    <ListItem disableGutters key={i} sx={{ border: '1px grey solid', margin: '20px' }}>
                        <ListItemButton onClick={() => handleListItemClick(q)}>
                            <ListItemText primary={q} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function ExampleQuestions(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(questions[1]);

    const handleClickOpen = () => {
        setSelectedValue('');
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        if(value !=='') {
            props.handleClearChat();
            props.handleSubmit(value);
        }
    };

    return (
        <div>
            <Tooltip title="Sample Questions" disableInteractive>
                <IconButton onClick={handleClickOpen}>
                    <QuizIcon />
                </IconButton>
            </Tooltip>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}