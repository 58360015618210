import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';


function CitationResponse(props) {

  const rawResponse = props.data.completion; // //props.data.completion[0].message.content;
  const citationsData = props.data.citations;
  const handleCitationClick = props.handleCitationClick;

  const [formattedText, setFormattedText] = useState("");
  const [citations, setCitations] = useState([]);

  const handleClick = (index) => {
    let keyRef = citations[index];
    let citation = citationsData.find(citation => citation.doc === keyRef);
    handleCitationClick(citation);
  };

  useEffect(() => {
    const citationRegex = /\[([^\]]+)\]/g;

    let matches;
    const uniqueCitations = new Map();

    let modifiedText = rawResponse;
    let counter = 1;

    while ((matches = citationRegex.exec(rawResponse)) !== null) {
      const citation = matches[1]; //.split("|")[0];
      if (!uniqueCitations.has(citation)) {
        uniqueCitations.set(citation, counter++);
      }
      const refNumber = uniqueCitations.get(citation);
      modifiedText = modifiedText.replace(matches[0], `<sup>${refNumber} </sup>`);
    }

    // Replace \n with <br />
    modifiedText = modifiedText.replace(/\n/g, "<br />");

    setFormattedText(modifiedText);
    setCitations(Array.from(uniqueCitations.keys()));
  }, [rawResponse]);

  return (
    <Box>

      <div dangerouslySetInnerHTML={{ __html: formattedText }} />

      {citations.length > 0 && <div style={{ marginTop: '20px' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >{citations.length} References</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {citations.map((citation, index) => (
              <Chip key={index} sx={{ marginRight: '10px', marginBottom: '10px' }} label={index + 1 + " " + citation.split('|')[0]} variant="outlined" onClick={() => handleClick(index)} />
            ))}
          </AccordionDetails>
        </Accordion>
      </div>}
    </Box>
  );
}

export default CitationResponse;
