
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

const ToolCard = (props) => {
    const navigate = useNavigate();

    return (
        <Card sx={{margin: '10px'}}>

            <Box sx={{ 
                position: 'relative',
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'left', 
                backgroundColor: 'white',
                width: '300px', 
                height: '250px',  
                paddingLeft: '30px', 
                paddingRight: '30px', 
                paddingBottom: '25px', 
                marginTop: '20px' }}
            >
                
                <Typography variant="h6" component="div" sx={{ marginTop: '20px', marginBottom: '15px', fontWeight: '600' }}>
                    {props.title}
                </Typography>
                <Typography variant="body1" >
                    {props.description}
                </Typography>

                <Box sx={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                <Button
                    onClick={() => navigate(props.path)}
                    variant="contained"
                    sx={{
                        marginTop: '20px', marginBottom: '20px', backgroundColor: '#db1f26', '&:hover': {
                            backgroundColor: '#db1f26',
                        }
                    }}
                    disabled={props.disabled ? true : false}
                >
                    VIEW
                </Button>
                </Box>


            </Box>
        </Card>
    );
};

export default ToolCard;
