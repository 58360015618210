import React, { useState, useEffect  } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { API_BASE_URL } from '../config';
import { Box } from '@mui/material';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AcronymFilter from '../components/AcronymFilter';

export const Acronyms = () => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [acronyms, setAcronyms] = useState([]);

    // const { error, execute, isLoading } = useFetchWithMsal({
   const { error, execute } = useFetchWithMsal({
        //scopes: protectedResources.apiTodoList.scopes.write
    });

    useEffect(() => {
        execute("GET", `${API_BASE_URL}/acronyms`).then((response) => {
            setAcronyms(response);
            });
    }, [execute]);
    

    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (<>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '50px' }}>
                        <Paper sx={{ width: '100%', maxWidth: '1000px' }}>
                            <Box sx={{ width: '100%'}}>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '30px', paddingBottom: '15px' }}>
                                    <Typography variant="h6" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                        QR Acronyms
                                    </Typography>
                                </Box>
                                {/* {isLoading ? <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}>Loading...</Typography> : null} */}
                                {error ? <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}>Error: {error}</Typography> : null}
                                {acronyms ? <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}>
                                  <strong>Note:</strong> Some acronyms and their definitions might not be accurate. 
                                  This list was generated automatically using a Large Language Model's effort to extract acronyms from the documents.
                                  <br/><br/>
                                  <strong>{acronyms.length}</strong> acronyms found. 
                                  </Typography> : null}
                                {acronyms ? <AcronymFilter acronyms={acronyms}/> : <Typography variant="body1" sx={{ paddingLeft: '30px', paddingBottom: '15px' }}> Loading acronyms...</Typography>}
                            </Box>
                        </Paper>

                    </Box>
                </>) : null}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                Unauthorised
            </UnauthenticatedTemplate>

        </>
    );
};