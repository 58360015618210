import React from 'react';
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import logo from '../images/qr-logo-main.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const NavigationBar = () => {

    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', marginBottom: '50px' }}>
            <Box sx={{ display: 'flex', width: '100%', height: '70px', justifyContent: 'space-between', alignItems: 'center' }}>

                <Box sx={{ marginLeft: '20px', marginRight: '30px', marginTop: '-8px' }}>
                    <img src={logo} width="200px" alt="Queensland Rail POC" />
                </Box>

                <AuthenticatedTemplate>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            onClick={() => navigate('/')}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Home
                        </Button>
                        <Button
                            onClick={() => navigate('/ask')}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Ask
                        </Button>
                        <Button
                            onClick={() => navigate('/acronyms')}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Acronyms
                        </Button>
                        <Button
                            onClick={() => navigate('/document')}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Doc Info
                        </Button>
                         
                    </Box>
                    <Box sx={{ marginRight: '20px' }}>
                        <Button
                            onClick={handleLogoutRedirect}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Sign out
                        </Button>
                    </Box>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <Box sx={{ marginRight: '20px' }}>
                        <Button
                            onClick={handleLoginRedirect}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Sign in
                        </Button>
                    </Box>
                </UnauthenticatedTemplate>
            </Box>
        </AppBar>
    );
};
