import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

function SimpleDialog(props) {
    const { onClose, open, acronym  } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Documents</DialogTitle>
            <Box sx={{paddingLeft: '25px', paddingRight: '25px'}}>
                <Typography sx={{fontWeight: '600', marginRight: '20px'}} variant="body">
                        {acronym.a}
                </Typography>
                <Typography variant="body">
                        {acronym.d}
                </Typography>
            </Box>

            <Box sx={{ pt: 0, margin: '20px'}}>
                {acronym.n.map((ref, i) => (
                  <Button style={{margin: '2px', width: '120px'}} variant="outlined" key={i}>{ref}</Button>
                ))}
            </Box>

        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function AcronymReferences(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(props.acronym) setOpen(true);
    }, [props.acronym]);
    
    const handleClose = () => {
        setOpen(false);
        props.handleClear();
    };

    return (
        <div>
            {props.acronym &&<SimpleDialog
                acronym={props.acronym}
                open={open}
                onClose={handleClose}
            />}
        </div>
    );
}