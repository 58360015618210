import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';

export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar />

            {props.children}

            <AuthenticatedTemplate>
                {/* <footer>
                    <center>
                       Made by Time Under Tension
                    </center>
                </footer> */}
            </AuthenticatedTemplate>
        </>
    );
};
