import React, { useState, useEffect  } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
//import useFetchWithMsal from '../hooks/useFetchWithMsal';
import Input from '../components/input';
import { Box } from '@mui/material';
import CitationResponse from '../components/CitationResponse';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import BotRed from '../images/bot_red.png';
import Chip from '@mui/material/Chip';
import { WS_BASE_URL } from '../config';
import ExampleQuestions from '../components/ExampleQuestions';
//import { IdTokenData } from '../components/DataDisplay';


// QR red: db1f26

export const Ask = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [citationOpen, setCitationOpen] = useState(false);
    const [citationContent, setCitationContent] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    /*
    const { error, execute, isLoading } = useFetchWithMsal({
        //scopes: protectedResources.apiTodoList.scopes.write
    });
    */

    const [reconnectRef, setReconnectRef] = useState(0);

    const [ws, setWs] = useState(null);

    useEffect(() => {

        const wsClient = new WebSocket(WS_BASE_URL);
        wsClient.onopen = () => console.log('Connected to WS server');


        let lastMessage = "";
        wsClient.onmessage = (event) => {

            const response = JSON.parse(event.data);

            if (response.type === 'rag-query') {
                updateLastMessageWithJSON({ query: response.message });

            } else if (response.type === 'rag-generating') {
                updateLastMessageWithJSON({ generating: response.message, completion: '' });

            } else if (response.type === 'rag-citations') {
                    updateLastMessageWithJSON({ citations: response.message });

            } else if (response.type === 'rag-stream') {

                setChatHistory(prevHistory => {
                    const updatedHistory = [...prevHistory];

                    if (lastMessage !== response.message) {
                        updatedHistory[updatedHistory.length - 1].completion += response.message;
                        lastMessage = response.message;
                    }
                    return updatedHistory;
                });

            }
        };

        wsClient.onclose = (event) => {
            if (event.wasClean) {
                console.log(`Closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                console.log('Connection died');
            }
            // Optionally handle reconnections or inform the user.
            console.log("Attempting to reconnect...");
            setReconnectRef(reconnectRef + 1);
        };

        wsClient.onerror = (error) => {
            console.error("WebSocket Error:", error);
            // Optionally display an error to the user or try to reconnect.
        };

        setWs(wsClient);

        return () => {
            wsClient.close();
        };

    }, [reconnectRef]);

    function updateLastMessageWithJSON(jsonObject) { //(prevChatHistory,jsonObject)

        setChatHistory(prevChatHistory => {
            // Ensure there is at least one item in the array
            if (prevChatHistory.length === 0) return prevChatHistory;

            const allExceptLast = prevChatHistory.slice(0, -1);
            const lastMessage = prevChatHistory[prevChatHistory.length - 1];

            // Modify the last message in some way (e.g., mark it as read)
            const updatedLastMessage = {
                ...lastMessage,
                ...jsonObject
            };

            return [...allExceptLast, updatedLastMessage];
        });

    }

    const ParseAPIString = ({ apiString }) => {

        const paragraphs = apiString.split(/\n/g);

        return (
            <div className="api-output">
                {paragraphs.map((paragraph, idx) => {
                    if (paragraph.includes("\t• \t")) {
                        return (
                            // <div key={idx} className="paragraph">
                            <ul>
                                {paragraph.split(/\t• \t/).map((bullet, bidx) => (
                                    bullet ? <li key={bidx}>{bullet}</li> : null
                                ))}
                            </ul>
                            // </div>
                        );
                    } else {
                        return (
                            // <div key={idx} className="paragraph">
                            <p>{paragraph}</p>
                            // </div>
                        );
                    }
                })}
            </div>
        );
    };

    const getLastChatHistory = () => {
        if (chatHistory.length > 0) {
            let lastChat = chatHistory[chatHistory.length - 1];
            let lastChatHistory = lastChat.chatHistory;
            return lastChatHistory;
        } else {
            return [];
        }
    }

    const handleClearChat = () => {
        setChatHistory([]);
        setCitationContent('');
        setCitationOpen(false);
    }



    const handleSubmit = (question) => {

        if (question === '') {
            return;
        }


        //setApiResponseData('');
        setChatHistory(prevChatHistory => [...prevChatHistory, { question: question }]);

        let postData = {
            "text": question,
            "selectFields": "id, docNumber, docTokens, title, type, fileName, page, pageCount, filePaths, prevNodeID, nextNodeID, content, isFragmented, originalPath",
            "k": 8,
            "searchType": "vector",
            "responseType": "completion",
            "chatHistory": getLastChatHistory()
        }

        if (ws) {
            ws.send(JSON.stringify({ type: 'rag', data: postData }));
        }

        /*

        execute("POST", 'http://localhost:8081/api/rag', postData).then((response) => {
            //setApiResponseData(response);
            setChatHistory(prevChatHistory => {
                // Ensure there is at least one item in the array
                if (prevChatHistory.length === 0) return prevChatHistory;

                const allExceptLast = prevChatHistory.slice(0, -1);
                const lastMessage = prevChatHistory[prevChatHistory.length - 1];

                // Modify the last message in some way (e.g., mark it as read)
                const updatedLastMessage = {
                    ...lastMessage,
                    ...response
                };

                return [...allExceptLast, updatedLastMessage];
            });
        })

        */

    }

    const handleCitationClick = (citation) => {
        setCitationOpen(true);
        setCitationContent(citation);
    }



    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (<>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>


                        <Paper sx={{ width: '100%', maxWidth: '1000px' }}>
                            <Box sx={{ width: '100%', height: 'calc(100vh - 150px)', position: 'relative' }}>


                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '30px', paddingBottom: '15px' }}>
                                    <Typography variant="h6" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                        Ask QR Docs
                                    </Typography>
                                    <Button variant="text" sx={{ marginTop: '20px', marginRight: '20px' }} onClick={() => handleClearChat(false)}>Clear</Button>
                                </Box>

                                <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 305px)', padding: '30px', backgroundColor: '#EEEEEE' }}>

                                    {chatHistory.map((chat, idx) => (
                                        <Box key={idx} sx={{ marginBottom: '60px' }}>

                                            <Box sx={{ display: 'flex', alignItems: 'top', justifyContent: 'right' }}>
                                                <Card sx={{ marginBottom: '30px' }}>
                                                    <Box sx={{ backgroundColor: 'white', paddingTop: '15px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '25px', marginTop: '20px' }}>
                                                        <Typography variant="body1" >
                                                            {chat.question}
                                                        </Typography>
                                                    </Box>
                                                </Card>
                                                <Box>
                                                    <AccountCircleIcon sx={{ fontSize: 35, marginTop: '10px', marginLeft: '10px', }} />
                                                </Box>

                                            </Box>

                                            {chat.query ?
                                                <Box sx={{ marginBottom: '30px' }}>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        Searching for: <strong>{chat.query}</strong>
                                                    </Typography>
                                                    {chat.generating && <Typography variant="caption" display="block" gutterBottom>
                                                        {chat.generating}
                                                    </Typography>}

                                                </Box>
                                                : null}


                                            <Box sx={{ display: 'flex', alignItems: 'top' }}>
                                                <Box>
                                                    <img src={BotRed} alt="bot" style={{ width: '40px', height: '40px', marginTop: '10px', marginRight: '10px' }} />
                                                    {/* <SmartToyIcon sx={{ fontSize: 30, marginTop: '10px', marginRight: '10px' }} /> */}
                                                </Box>
                                                <Card>
                                                    <Box sx={{ backgroundColor: 'white', paddingTop: '15px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '25px', marginTop: '20px' }}>
                                                        {chat.completion ? <CitationResponse
                                                            data={{ completion: chat.completion, citations: chat.citations }}
                                                            handleCitationClick={handleCitationClick}
                                                        />
                                                            : <CircularProgress style={{ width: '25px', height: '25px' }} />}
                                                    </Box>
                                                </Card>
                                            </Box>

                                        </Box>
                                    ))}

                                    {/* {isLoading ? <p>Fetching data from API protected by Microsoft Identity Platform</p> : null} */}

                                </Box>

                                <Card>
                                    <Box sx={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100px'}}>

                                           <Box sx={{paddingLeft: '40px', width: '100%'}}>
                                                <Input handleSubmit={handleSubmit} />                
                                           </Box>
                                                
                                            <Box sx={{paddingLeft: '20px', paddingRight: '20px'}}>
                                                 <ExampleQuestions handleSubmit={handleSubmit} handleClearChat={handleClearChat}/>
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                </Card>

                            </Box>
                        </Paper>


                        {citationOpen &&
                            <Paper sx={{ width: '100%', maxWidth: '450px', marginLeft: '20px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '30px' }}>
                                    <Typography variant="h6" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                        References
                                    </Typography>
                                    <IconButton onClick={() => setCitationOpen(false)}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ overflowY: 'auto', width: '100%', height: 'calc(100vh - 200px)', position: 'relative', paddingTop: '20px', paddingLeft: '30px', paddingRight: '20px' }}>
                                    {citationContent ? <Box >
                                        <Typography variant="h6" component="div" sx={{ marginBottom: '10px' }}>
                                            {citationContent.title}
                                        </Typography>
                                        <hr />

                                        <Box sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between' }}>
                                            <div>
                                                <Typography variant="body2" color="text.secondary">
                                                    Document: {citationContent.fileName}
                                                </Typography>
                                                <Typography sx={{ marginBottom: '20px' }} variant="body2" color="text.secondary">
                                                    Page: {citationContent.page + 1}
                                                </Typography>
                                            </div>
                                            <Chip label={citationContent.type} color="primary" size="small" variant="outlined" />
                                        </Box>

                                        <hr />
                                        <ParseAPIString apiString={citationContent.content} />
                                    </Box>
                                        : ""}
                                </Box>
                            </Paper>}
                    </Box>
                </>) : null}
            </AuthenticatedTemplate>


            {/* <pre>{JSON.stringify(chatHistory, null, 2)}</pre> */}

        </>
    );
};