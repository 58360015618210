import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ToolCard from '../components/ToolCard';

export const Home = () => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount && <>
                    <Box sx={{ marginLeft: '30px' }}>
                        <Typography variant="h5" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                            Hi {activeAccount.idTokenClaims.given_name},
                        </Typography>
                        <Typography variant="body1" >
                            You have access to the following modules:
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <ToolCard
                            title="Ask QR Docs"
                            description="Use natural language to ask questions about QR SEMS documents."
                            path="/ask"
                            disabled={false}
                        />
                        <ToolCard
                            title="Acronyms"
                            description="Index of QR acronyms from sample documents."
                            path="/acronyms"
                            disabled={false}
                        />
                        <ToolCard
                            title="Document Info"
                            description="View summary, referenced documents, URLs and acronyms."
                            path="/document"
                            disabled={false}
                        />
                    </Box>
                </>}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 'calc(100vh - 150px)' }}>
                    <Card sx={{ marginBottom: '30px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', paddingTop: '15px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '25px', marginTop: '20px' }}>
                            <Typography variant="h5" component="div" sx={{ marginTop: '20px', fontWeight: '600' }}>
                                Queensland Rail POC
                            </Typography>
                            <Typography variant="body1" >
                                Authentication Required
                            </Typography>
                            <Button
                                onClick={handleLoginRedirect}
                                variant="contained"
                                sx={{
                                    marginTop: '20px', marginBottom: '20px', backgroundColor: '#db1f26',
                                    '&:hover': {
                                        backgroundColor: '#db1f26',
                                    }
                                }}
                            >
                                Sign in
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </UnauthenticatedTemplate>

        </>
    );
};